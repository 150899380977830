import i18n from '@/i18n'

export enum Sections {
  budget = 'budget',
  timeline = 'timeline',
  deadline = 'deadline',
}

export enum Days {
  monday = 'Monday',
  tuesday = 'Tuesday',
  wednesday = 'Wednesday',
  thursday = 'Thursday',
  friday = 'Friday',
  saturday = 'Saturday',
  sunday = 'Sunday',
}

export const DayData: any = {
  [Days.monday]: '1',
  [Days.tuesday]: '2',
  [Days.wednesday]: '3',
  [Days.thursday]: '4',
  [Days.friday]: '5',
  [Days.saturday]: '6',
  [Days.sunday]: '7',
}

export enum Currencies {
  eur = 'eur',
  usd = 'usd',
}

export const CurrencyData: any = {
  [Currencies.eur]: i18n.t('Eur (Euro)'),
  [Currencies.usd]: i18n.t('USD (United States dollar)'),
}

export enum BudgetType {
  fixed = 'fixed',
  designerSuggestion = 'designer-suggestion',
}

export const BudgetTypeData: any = {
  [BudgetType.fixed]: i18n.t('Fixed'),
  [BudgetType.designerSuggestion]: i18n.t('Let designers evaluate the price.'),
}

export enum TimelineType {
  ongoing = 'ongoing',
  oneTime = 'one-time',
}

export const TimelineTypeData: any = {
  [TimelineType.ongoing]: i18n.t('Ongoing'),
  [TimelineType.oneTime]: i18n.t('One-time'),
}
