import { Component, Prop, Vue } from 'vue-property-decorator'
import ProgressBase from '@/shared/classes/progress/progress'

@Component
export class ProjectStepMixin extends Vue {
  @Prop() progress!: ProgressBase

  created(): void {
    if (this.$route.query?.step !== this.progress.current) {
      this.$router.push({ query: { ...this.$route.query, step: this.progress.current } })
    }
  }
}
