import DefaultSection from '@/components/form/sections/DefaultSection.vue'

export default class FormSection {
  key: string = null!
  title: string = null!
  logoType: string = null!
  description: string = null!
  image: string = null!
  component: any = DefaultSection
  meta: any = { noBackground: false }
  wrapperClass: string = ''
  icon: string = ''
  visibleIf: (data: any) => boolean = () => true

  setKey(key: string): this {
    this.key = key

    return this
  }

  setTitle(title: string): this {
    this.title = title

    return this
  }

  setDescription(description: string): this {
    this.description = description

    return this
  }

  setImage(image: string): this {
    this.image = image

    return this
  }

  setLogoType(logoType: string): this {
    this.logoType = logoType

    return this
  }

  setComponent(component: any): this {
    this.component = component

    return this
  }

  setMeta(meta: any): this {
    this.meta = meta

    return this
  }

  setVisibleIf(visibleIf: (data: any) => boolean): this {
    this.visibleIf = visibleIf

    return this
  }

  setWrapperClass(wrapperClass: string): this {
    this.wrapperClass = wrapperClass

    return this
  }

  setIcon(icon: string): this {
    this.icon = icon

    return this
  }
}
