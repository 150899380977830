import ProjectStep from '@/views/projects/create/Steps/ProjectStep.vue'
import StyleStep from '@/views/projects/create/Steps/StyleStep.vue'
import InformationStep from '@/views/projects/create/Steps/InformationStep.vue'
import PlansStep from '@/views/projects/create/Steps/PlansStep.vue'
import BudgetAndTimelineStep from '@/views/projects/create/Steps/BudgetAndTimelineStep.vue'
import CheckoutStep from '@/views/projects/create/Steps/CheckoutStep.vue'
import InvitationsStep from '@/views/projects/create/Steps/InvitationsStep.vue'
import i18n from '@/i18n'

export enum Step {
  project = 'project',
  style = 'style',
  information = 'information',
  budgetAndTimeline = 'budget-timeline',
  plan = 'plan',
  invitations = 'invitations',
  checkout = 'checkout',
}

export enum Stage {
  inception = 'inception',
  advanced = 'advanced',
  notSure = 'not-sure',
}

export enum Experience {
  beginner = 'beginner',
  advanced = 'advanced',
  expert = 'expert',
}

const projectCreationConfig = {
  componentMap: {
    [Step.project]: ProjectStep,
    [Step.style]: StyleStep,
    [Step.information]: InformationStep,
    [Step.budgetAndTimeline]: BudgetAndTimelineStep,
    [Step.plan]: PlansStep,
    [Step.invitations]: InvitationsStep,
    [Step.checkout]: CheckoutStep,
  },
  stages: [
    {
      key: Stage.inception,
      title: i18n.t('Inception stage'),
    },
    {
      key: Stage.advanced,
      title: i18n.t('Advanced stage'),
    },
    {
      key: Stage.notSure,
      title: i18n.t('Not sure'),
    },
  ],
  experience: [
    {
      key: Experience.beginner,
      title: i18n.t('Beginner'),
    },
    {
      key: Experience.advanced,
      title: i18n.t('Advanced'),
    },
    {
      key: Experience.expert,
      title: i18n.t('Expert'),
    },
  ],
  categories: [
    {
      key: 'abstract',
      title: i18n.t('Abstract'),
    },
    {
      key: 'accounting-financial',
      title: i18n.t('Accounting & Financial'),
    },
    {
      key: 'agriculture',
      title: i18n.t('Agriculture'),
    },
    {
      key: 'airline',
      title: i18n.t('Airline'),
    },
    {
      key: 'animal-pet',
      title: i18n.t('Animal & Pet'),
    },
    {
      key: 'apparel',
      title: i18n.t('Apparel'),
    },
    {
      key: 'architecture',
      title: i18n.t('Architecture'),
    },
    {
      key: 'art-design',
      title: i18n.t('Art & Design'),
    },
    {
      key: 'attorney-law',
      title: i18n.t('Attorney & Law'),
    },
    {
      key: 'automotive',
      title: i18n.t('Automotive'),
    },
    {
      key: 'baby',
      title: i18n.t('Baby'),
    },
    {
      key: 'bakery',
      title: i18n.t('Bakery'),
    },
    {
      key: 'band',
      title: i18n.t('Band'),
    },
    {
      key: 'bank',
      title: i18n.t('Bank'),
    },
    {
      key: 'bar-nightclub',
      title: i18n.t('Bar & Nightclub'),
    },
    {
      key: 'barber-shop',
      title: i18n.t('Barber Shop'),
    },
    {
      key: 'basketball',
      title: i18n.t('Basketball'),
    },
    {
      key: 'blog',
      title: i18n.t('Blog'),
    },
    {
      key: 'business-consulting',
      title: i18n.t('Business & Consulting'),
    },
    {
      key: 'catering',
      title: i18n.t('Catering'),
    },
    {
      key: 'charity',
      title: i18n.t('Charity'),
    },
    {
      key: 'childcare',
      title: i18n.t('Childcare'),
    },
    {
      key: 'church',
      title: i18n.t('Church'),
    },
    {
      key: 'cleaning',
      title: i18n.t('Cleaning'),
    },
    {
      key: 'communications',
      title: i18n.t('Communications'),
    },
    {
      key: 'community-non-profit',
      title: i18n.t('Community & Non-profit'),
    },
    {
      key: 'company',
      title: i18n.t('Company'),
    },
    {
      key: 'computer',
      title: i18n.t('Computer'),
    },
    {
      key: 'construction',
      title: i18n.t('Construction'),
    },
    {
      key: 'cosmetics-beauty',
      title: i18n.t('Cosmetics & Beauty'),
    },
    {
      key: 'creative',
      title: i18n.t('Creative'),
    },
    {
      key: 'credit-card',
      title: i18n.t('Credit Card'),
    },
    {
      key: 'dating',
      title: i18n.t('Dating'),
    },
    {
      key: 'dental',
      title: i18n.t('Dental'),
    },
    {
      key: 'dj',
      title: 'DJ',
    },
    {
      key: 'education',
      title: i18n.t('Education'),
    },
    {
      key: 'energy',
      title: i18n.t('Energy'),
    },
    {
      key: 'engineering',
      title: i18n.t('Engineering'),
    },
    {
      key: 'entertainment-arts',
      title: i18n.t('Entertainment & Arts'),
    },
    {
      key: 'environmental',
      title: i18n.t('Environmental'),
    },
    {
      key: 'event',
      title: i18n.t('Event'),
    },
    {
      key: 'fashion',
      title: i18n.t('Fashion'),
    },
    {
      key: 'film',
      title: i18n.t('Film'),
    },
    {
      key: 'floral',
      title: i18n.t('Floral'),
    },
    {
      key: 'food-drink',
      title: i18n.t('Food & Drink'),
    },
    {
      key: 'football',
      title: i18n.t('Football'),
    },
    {
      key: 'games-recreational',
      title: i18n.t('Games & Recreational'),
    },
    {
      key: 'gaming',
      title: i18n.t('Gaming'),
    },
    {
      key: 'graphic-design',
      title: i18n.t('Graphic Design'),
    },
    {
      key: 'health',
      title: i18n.t('Health'),
    },
    {
      key: 'gome-furnishing',
      title: i18n.t('Home Furnishing'),
    },
    {
      key: 'home-improvement',
      title: i18n.t('Home improvement'),
    },
    {
      key: 'hr',
      title: 'HR',
    },
    {
      key: 'industrial',
      title: i18n.t('Industrial'),
    },
    {
      key: 'infrastructure',
      title: i18n.t('Infrastructure'),
    },
    {
      key: 'insurance',
      title: i18n.t('Insurance'),
    },
    {
      key: 'internet',
      title: i18n.t('Internet'),
    },
    {
      key: 'jewlery',
      title: i18n.t('Jewelry'),
    },
    {
      key: 'landscaping',
      title: i18n.t('Landscaping'),
    },
    {
      key: 'letter-based',
      title: i18n.t('Letter based'),
    },
    {
      key: 'logistics',
      title: i18n.t('Logistics'),
    },
    {
      key: 'makeup',
      title: i18n.t('Makeup'),
    },
    {
      key: 'marketing',
      title: i18n.t('Marketing'),
    },
    {
      key: 'massage',
      title: i18n.t('Massage'),
    },
    {
      key: 'medical-pharmaceutical',
      title: i18n.t('Medical & Pharmaceutical'),
    },
    {
      key: 'music',
      title: i18n.t('Music'),
    },
    {
      key: 'online-shop',
      title: i18n.t('Online Shop'),
    },
    {
      key: 'outdoor',
      title: i18n.t('Outdoor'),
    },
    {
      key: 'personal',
      title: i18n.t('Personal'),
    },
    {
      key: 'photography',
      title: i18n.t('Photography'),
    },
    {
      key: 'physical-fitness',
      title: i18n.t('Physical Fitness'),
    },
    {
      key: 'plumbing',
      title: i18n.t('Plumbing'),
    },
    {
      key: 'podcast',
      title: i18n.t('Podcast'),
    },
    {
      key: 'political',
      title: i18n.t('Political'),
    },
    {
      key: 'public-relation',
      title: i18n.t('Public Relation'),
    },
    {
      key: 'real-estate-mortgage',
      title: i18n.t('Real Estate & Mortgage'),
    },
    {
      key: 'religious',
      title: i18n.t('Religious'),
    },
    {
      key: 'restaurant',
      title: i18n.t('Restaurant'),
    },
    {
      key: 'retail',
      title: i18n.t('Retail'),
    },
    {
      key: 'school',
      title: i18n.t('School'),
    },
    {
      key: 'security',
      title: i18n.t('Security'),
    },
    {
      key: 'spa-aesthetics',
      title: i18n.t('Spa & Aesthetics'),
    },
    {
      key: 'sport',
      title: i18n.t('Sport'),
    },
    {
      key: 'technology',
      title: i18n.t('Technology'),
    },
    {
      key: 'transportation',
      title: i18n.t('Transportation'),
    },
    {
      key: 'travel-hotel',
      title: i18n.t('Travel & Hotel'),
    },
    {
      key: 'trucking',
      title: i18n.t('Trucking'),
    },
    {
      key: 'tv',
      title: 'TV',
    },
    {
      key: 'twitch',
      title: i18n.t('Twitch'),
    },
    {
      key: 'vintage',
      title: i18n.t('Vintage'),
    },
    {
      key: 'wedding-service',
      title: i18n.t('Wedding Service'),
    },
    {
      key: 'wellness',
      title: i18n.t('Wellness'),
    },
    {
      key: 'yoga',
      title: i18n.t('Yoga'),
    },
    {
      key: 'youtube',
      title: 'YouTube',
    },
    {
      key: 'other',
      title: i18n.t('Other'),
    },
  ],
  logoStyles: [
    {
      key: 'abstract-mark',
      title: i18n.t('Abstract Mark'),
    },
    {
      key: 'caligraphy',
      title: i18n.t('Caligraphy'),
    },
    {
      key: 'combination-mark',
      title: i18n.t('Combination Mark'),
    },
    {
      key: 'emblem-mark',
      title: i18n.t('Emblem Mark'),
    },
    {
      key: 'letter-mark',
      title: i18n.t('Letter Mark'),
    },
    {
      key: 'mascot-mark',
      title: i18n.t('Mascot Mark'),
    },
    {
      key: 'picture-mark',
      title: i18n.t('Picture Mark'),
    },
    {
      key: 'word-mark',
      title: i18n.t('Word Mark'),
    },
  ],
  characteristics: [
    {
      key: 'classic-modern',
      options: [
        {
          key: 'classic',
          title: i18n.t('Classic'),
        },
        {
          key: 'none',
          title: i18n.t('Neutral'),
        },
        {
          key: 'modern',
          title: i18n.t('Modern'),
        },
      ],
    },
    {
      key: 'minimal-complex',
      options: [
        {
          key: 'minimal',
          title: i18n.t('Minimal'),
        },
        {
          key: 'none',
          title: i18n.t('Neutral'),
        },
        {
          key: 'complex',
          title: i18n.t('Complex'),
        },
      ],
    },
    {
      key: 'mature-youthful',
      options: [
        {
          key: 'mature',
          title: i18n.t('Mature'),
        },
        {
          key: 'none',
          title: i18n.t('Neutral'),
        },
        {
          key: 'youthful',
          title: i18n.t('Youthful'),
        },
      ],
    },
    {
      key: 'feminine-masculine',
      options: [
        {
          key: 'feminine',
          title: i18n.t('Feminine'),
        },
        {
          key: 'none',
          title: i18n.t('Neutral'),
        },
        {
          key: 'masculine',
          title: i18n.t('Masculine'),
        },
      ],
    },
    {
      key: 'refined-playful',
      options: [
        {
          key: 'refined',
          title: i18n.t('Refined'),
        },
        {
          key: 'none',
          title: i18n.t('Neutral'),
        },
        {
          key: 'playful',
          title: i18n.t('Playful'),
        },
      ],
    },
    {
      key: 'geometric-organic',
      options: [
        {
          key: 'geometric',
          title: i18n.t('Geometric'),
        },
        {
          key: 'none',
          title: i18n.t('Neutral'),
        },
        {
          key: 'organic',
          title: i18n.t('Organic'),
        },
      ],
    },
    {
      key: 'luxury-modest',
      options: [
        {
          key: 'luxury',
          title: i18n.t('Luxury'),
        },
        {
          key: 'none',
          title: i18n.t('Neutral'),
        },
        {
          key: 'modest',
          title: i18n.t('Modest'),
        },
      ],
    },
    {
      key: 'abstract-naturalistic',
      options: [
        {
          key: 'abstract',
          title: i18n.t('Abstract'),
        },
        {
          key: 'none',
          title: i18n.t('Neutral'),
        },
        {
          key: 'naturalistic',
          title: i18n.t('Naturalistic'),
        },
      ],
    },
  ],
  colours: [
    {
      key: 'red',
      title: i18n.t('Red'),
      colors: {
        from: '#d32f2f',
        to: '#f44336',
      },
      meaning: i18n.t('Energy, action, passion, love;'),
    },
    {
      key: 'orange',
      title: i18n.t('Orange'),
      colors: {
        from: '#f57c00',
        to: '#ff9800',
      },
      meaning: i18n.t('Creativity, adventure, happiness;'),
    },
    {
      key: 'yellow',
      title: i18n.t('Yellow'),
      colors: {
        from: '#fbc02d',
        to: '#ffeb3b',
      },
      meaning: i18n.t('Energy, intellect, fun, youth;'),
    },
    {
      key: 'green',
      title: i18n.t('Green'),
      colors: {
        from: '#689f38',
        to: '#8bc34a',
      },
      meaning: i18n.t('Harmony, success;'),
    },
    {
      key: 'teal',
      title: i18n.t('Teal'),
      colors: {
        from: '#00796b',
        to: '#009688',
      },
      meaning: i18n.t('Empathy, compassion, clarity, precision;'),
    },
    {
      key: 'blue',
      title: i18n.t('Blue'),
      colors: {
        from: '#1976d2',
        to: '#2196f3',
      },
      meaning: i18n.t('Knowledge, trust, calm, honesty;'),
    },
    {
      key: 'purple',
      title: i18n.t('Purple'),
      colors: {
        from: '#512da8',
        to: '#673ab7',
      },
      meaning: i18n.t('Imagination, spirituality, royalty, luxury;'),
    },
    {
      key: 'pink',
      title: i18n.t('Pink'),
      colors: {
        from: '#c2185b',
        to: '#e91e63',
      },
      meaning: i18n.t('Intimacy, love, calm, nurture;'),
    },
    {
      key: 'black',
      title: i18n.t('Black'),
      colors: {
        from: '#151515',
        to: '#000000',
      },
      meaning: i18n.t('Formality, mystery, exclusivity, luxury;'),
    },
    {
      key: 'white',
      title: i18n.t('White'),
      textColor: 'black',
      colors: {
        from: '#eeeeee',
        to: '#ffffff',
      },
      meaning: i18n.t('Purity, balance, sophistication;'),
    },
  ],
}

export default projectCreationConfig
