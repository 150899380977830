var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.designers.designers && _vm.designers.designers.length > 0 && _vm.markedAndSelected && _vm.designers.loaded)?_c('div',{staticClass:"row"},_vm._l((_vm.designers.designers),function(designer){return _c('div',{key:designer.uuid,staticClass:"col-12 col-md-6 col-lg-4 col-xl-3 d-flex"},[_c('div',{staticClass:"designer-item w-100 d-flex flex-column",class:{
            disabled:
              (_vm.checkIfMaxLen && !_vm.checkIfChecked(designer.uuid, _vm.form.data.designers)) ||
              (_vm.originalSelection && _vm.field.options.editing && _vm.checkIfChecked(designer.uuid, _vm.originalSelection)),
          }},[_c('div',{staticClass:"designer-single__profile-image"},[(!_vm.getProfilePhoto(designer.media))?_c('div',{staticClass:"designer-profile__photo"},[_vm._v(" "+_vm._s(_vm.getFirstLetters(designer.full_name))+" ")]):_c('img',{staticClass:"designer-profile__photo",attrs:{"src":_vm.getProfilePhoto(designer.media)}})]),_c('div',{staticClass:"designer-item__block flex-grow-1 d-flex flex-column"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selection),expression:"selection"}],staticClass:"designer-item__checkbox",attrs:{"type":"checkbox","name":((_vm.field.key) + "-" + (designer.uuid)),"id":((_vm.field.key) + "-" + (designer.uuid)),"disabled":(_vm.checkIfMaxLen && !_vm.checkIfChecked(designer.uuid, _vm.form.data.designers)) ||
                  (_vm.originalSelection && _vm.field.options.editing && _vm.checkIfChecked(designer.uuid, _vm.originalSelection))},domProps:{"value":("" + (designer.uuid)),"checked":Array.isArray(_vm.selection)?_vm._i(_vm.selection,("" + (designer.uuid)))>-1:(_vm.selection)},on:{"input":function($event){return _vm.valueChange(designer, $event.target)},"change":function($event){var $$a=_vm.selection,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="" + (designer.uuid),$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selection=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selection=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selection=$$c}}}}),_c('h4',{staticClass:"designer-single__profile-heading",domProps:{"textContent":_vm._s(designer.full_name)}}),_c('p',[_c('AutoTranslator',{attrs:{"translation-data":designer.translations,"default-value":designer.about_me,"field-name":"about_me","max-length":"300"}})],1),_c('div',{staticClass:"designer-item__background"})]),_c('div',{staticClass:"mt-auto"},[(!_vm.checkIfDesignerChecked(designer.uuid))?_c('label',{staticClass:"btn btn--blue designer-item__button",attrs:{"for":((_vm.field.key) + "-" + (designer.uuid))}},[_vm._v(" "+_vm._s(_vm.$t('Invite'))+" ")]):_c('label',{staticClass:"btn btn--white designer-item__button",attrs:{"for":((_vm.field.key) + "-" + (designer.uuid))}},[_vm._v(" "+_vm._s(_vm.$t('Remove'))+" ")]),_c('label',{staticClass:"btn designer-item__button--white",on:{"click":function($event){return _vm.goToDesignerProfile(designer.slug || designer.uuid)}}},[_vm._v(" "+_vm._s(_vm.$t('View profile'))+" ")]),_c('ConfirmModal',{attrs:{"buttonText":_vm.$t('Remove designer'),"header":_vm.$t('Are you sure want to remove {designer} from invitations list?', {
                    designer: designer.full_name,
                  }),"id":("remove-designer-" + (designer.uuid))},on:{"accept":function($event){return _vm.removeDesigner(designer)}}})],1)])])])}),0):_vm._e(),(_vm.designers.meta && _vm.designers.meta.last_page > 1)?_c('Pagination',{attrs:{"page":_vm.designers.meta.current_page,"lastPage":_vm.designers.meta.last_page},on:{"on-page-click":function($event){return _vm.onPageClick($event)}}}):(!_vm.designers.loaded)?_c('div',{staticClass:"row"},_vm._l((8),function(index){return _c('DesignersSkeleton',{key:index,attrs:{"button":true}})}),1):(_vm.designers.designers.length < 0)?_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('No designers here'))+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }